import AppRoutes from './routes/AppRoutes.js';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
