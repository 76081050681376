import { PrinterHero, PrinterProductGrid } from '../sections/index.js'

const Printers = () => {

    return (
        <>
            <PrinterHero />
            <PrinterProductGrid />
        </>
    );
};

export default Printers;
  